import React, { useMemo, useRef, useState } from 'react';
import { isNumber, sum, last } from 'lodash';
import { rgba } from 'polished';
import Highcharts, { SeriesOptionsType } from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { portfolioCalculator, totalAnnualizedReturn } from '@yieldstreet/tool-kit';

import { Currency } from '../../../multi-item-banner/sub-components/common/currency/Currency';
import { DesktopOnly, MobileOnly } from '../../../../utils';

import { SwitchSelector } from '../SwitchSelector';
import {
  ButtonContainer,
  DetailsContainer,
  EarningsContainer,
  Label,
  MobileTargetContainer,
  OuterContainer,
  Paragraph,
  TargetContainer,
  TotalInvestment,
} from './Chart.style';
import {
  ChartColors,
  ChartDisplay,
  ColumnColors,
  PortfolioSimulatorProps,
  TabsDesktop,
  TabsMobile,
  TabsMobileDesc,
} from './Chart.model';
import { Typography } from '@mui/material';

export const Chart = ({ amounts, investments, showTotalInvestment }: PortfolioSimulatorProps) => {
  const chartComponentRef = useRef(null);

  const [chartDisplay, setChartDisplay] = useState(ChartDisplay.BOTH);

  const calculatedAmounts = useMemo(
    () =>
      investments.map((investment, index) =>
        amounts && isNumber(amounts[index]) ? amounts[index] : investment.defaultAmount
      ),
    [investments, amounts]
  );

  const { investmentsData, globalEarnings } = useMemo(
    () => portfolioCalculator(investments, calculatedAmounts),
    [investments, calculatedAmounts]
  );

  const totals = useMemo(() => {
    return {
      earnings: last(globalEarnings) || 0,
      investment: sum(calculatedAmounts),
      targetAnnualized: totalAnnualizedReturn(investments, calculatedAmounts),
    };
  }, [calculatedAmounts, globalEarnings, investments]);

  const options = useMemo(() => {
    let series: SeriesOptionsType[] = [];

    if (chartDisplay === ChartDisplay.PAYMENTS || chartDisplay === ChartDisplay.BOTH) {
      series = investmentsData.map((investment, index) => {
        return {
          type: 'column',
          name: investment.name,
          data: investment.payments,
          color: ColumnColors[index],
        };
      });
    }

    const categoriesSize = Math.max(globalEarnings.length - 2, 0);

    const categories: string[] = ['Today', ...Array(categoriesSize).fill('')];

    investments.forEach(investment => {
      categories[investment.term - 1] = `${investment.term} ${investment.termSuffix}`;
    });

    if (chartDisplay === ChartDisplay.EARNINGS || chartDisplay === ChartDisplay.BOTH) {
      series = [
        ...series,
        {
          lineColor: ChartColors[0],
          fillOpacity: 0.5,
          fillColor: {
            linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
            stops: [
              [0, rgba(ChartColors[0], 0.7)],
              [1, rgba(ChartColors[1], 0)],
            ],
          },
          threshold: null,
          name: 'Earnings',
          type: 'area',
          data: globalEarnings,
        },
      ];
    }

    return {
      credits: {
        enabled: false,
      },
      chart: {
        height: 208,
        marginRight: 15,
      },
      legend: {
        enabled: false,
      },
      title: {
        text: '',
      },
      tooltip: {
        valueDecimals: 0,
        valuePrefix: '$',
      },
      yAxis: {
        labels: {
          // eslint-disable-next-line no-template-curly-in-string
          format: '${value}',
        },
        gridLineDashStyle: 'dash',
        min: 0,
        title: {
          text: '',
        },
        stackLabels: {
          enabled: false,
        },
      },
      xAxis: {
        labels: {
          autoRotation: [0],
          allowOverlap: false,
          overflow: 'allow',
          padding: 10,
          align: 'center',
          y: 25,
        },
        categories: categories,
      },
      plotOptions: {
        column: {
          stacking: 'normal',
          dataLabels: {
            enabled: false,
          },
        },
        area: {
          marker: {
            enabled: false,
          },
        },
      },
      series,
    };
  }, [chartDisplay, globalEarnings, investments, investmentsData]);

  return (
    <>
      <OuterContainer>
        <DetailsContainer>
          <EarningsContainer>
            <Label variant="body2">Total earnings</Label>
            <Typography variant="h4">
              <Currency doNotRound forcePlaces={2} value={totals.earnings} />
            </Typography>
            {investments.length > 1 ? (
              <MobileOnly>
                <MobileTargetContainer>
                  <Label variant="body2">Target annualized return {`  `}</Label>
                  <Label semiBold sx={{ paddingLeft: 1 }} variant="body2">
                    {totals.targetAnnualized}%
                  </Label>
                </MobileTargetContainer>
              </MobileOnly>
            ) : (
              ''
            )}
          </EarningsContainer>
          {investments.length > 1 ? (
            <>
              <TargetContainer>
                <DesktopOnly>
                  <Label variant="body2">Total investment</Label>
                  <Typography variant="h5">
                    <Currency doNotRound forcePlaces={4} value={totals.investment} />
                  </Typography>
                </DesktopOnly>
              </TargetContainer>
              <TargetContainer>
                <DesktopOnly>
                  <Label variant="body2">Target annualized return</Label>
                  <Typography variant="h5">{totals.targetAnnualized}%</Typography>
                </DesktopOnly>
              </TargetContainer>
            </>
          ) : (
            ''
          )}
          <ButtonContainer>
            <MobileOnly>
              <SwitchSelector
                tabs={TabsMobile}
                onClick={tab => setChartDisplay(+tab.key)}
                activeKey={chartDisplay}
              />
              <Label variant="body2">{TabsMobileDesc[chartDisplay]}</Label>
            </MobileOnly>
            <DesktopOnly>
              <SwitchSelector
                tabs={TabsDesktop}
                onClick={tab => setChartDisplay(+tab.key)}
                activeKey={chartDisplay}
              />
            </DesktopOnly>
          </ButtonContainer>
        </DetailsContainer>
        <HighchartsReact highcharts={Highcharts} options={options} ref={chartComponentRef} />
      </OuterContainer>
      {showTotalInvestment && (
        <TotalInvestment>
          <Paragraph semiBold variant="body1">
            Total investment
          </Paragraph>
          <Paragraph semiBold variant="body1">
            <Currency doNotRound forcePlaces={4} value={totals.investment} />
          </Paragraph>
        </TotalInvestment>
      )}
    </>
  );
};
