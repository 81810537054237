import React, { FunctionComponent, useMemo } from 'react';
import { useTheme, ThemeProvider } from 'styled-components';
import { GridTheme, PartialGridConfig } from './common';

interface GridProviderProps {
  config?: PartialGridConfig;
}

export const GridProvider: FunctionComponent<GridProviderProps> = ({ children, config }) => {
  const globalTheme = useTheme() as GridTheme;
  const theme = useMemo(() => {
    if (config) {
      return { ...globalTheme, grid: { ...globalTheme.grid, ...config } };
    }
    return globalTheme;
  }, [config, globalTheme]);
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};
