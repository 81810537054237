import { TextBlockProps } from '../text-block-component/TextBlock.model';

export enum IconSize {
  Small = '32px',
  Medium = '48px',
  Large = '64px',
}

interface Stat {
  label: string;
  value: string;
}

export interface Advantage {
  icon: string;
  title: string;
  description: string;
  stat?: Stat;
}

export interface AdvantagesSectionProps extends Pick<TextBlockProps, 'align' | 'mobileAlign'> {
  items: Advantage[];
  label?: string;
  heading?: string;
  content?: string;
  iconsSize?: IconSize;
  hasPadding?: boolean;
}

export const childrenAmountSmallWidth = 4;
