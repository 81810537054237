import React, { FC, useCallback, useMemo, useState } from 'react';
import produce from 'immer';
import { Box, Typography } from '@mui/material';

import { DesktopOnly, MobileOnly } from '../../../utils';

import { AmountSelector } from '../components/AmountSelector';
import { Chart, ColumnColors } from '../components/Chart';
import { StrategySelector } from '../components/StrategySelector';
import { SwitchSelector } from '../components/SwitchSelector';

import { ChartProps, PortfolioSimulatorProps, Strategies } from './PortfolioSimulator.model';
import {
  Background,
  ChartContainer,
  ControlsHolder,
  Disclaimer,
  StrategyDesc,
} from './PortfolioSimulator.style';

const PortfolioChart: FC<ChartProps> = ({ initAmount, portfolioConfig }) => {
  const [showTotalInvestment, setShowTotalInvestment] = useState(false);
  const [strategy, setStrategy] = useState(0);
  const [amounts, setAmounts] = useState<number[][]>(initAmount);

  const investments = useMemo(() => {
    return portfolioConfig[strategy].investments;
  }, [portfolioConfig, strategy]);

  const onAmountChange = useCallback(
    (amount, index) => {
      setAmounts(
        produce(draft => {
          draft[strategy][index] = amount;
        })
      );
    },
    [strategy, setAmounts]
  );

  return (
    <>
      <ChartContainer>
        <Chart
          investments={investments}
          amounts={amounts[strategy]}
          showTotalInvestment={showTotalInvestment}
        />
      </ChartContainer>
      <ControlsHolder>
        <DesktopOnly>
          <StrategySelector
            onClick={option => setStrategy(+option.key)}
            activeKey={strategy}
            options={Strategies}
          />
        </DesktopOnly>
        <MobileOnly>
          <Box>
            <SwitchSelector
              tabs={Strategies}
              onClick={tab => setStrategy(+tab.key)}
              activeKey={strategy}
            />
            <StrategyDesc variant="body2">{Strategies[strategy].desc}</StrategyDesc>
          </Box>
        </MobileOnly>
        {investments.map((investment, index) => (
          <AmountSelector
            key={index}
            title={investment.name}
            annualizedYield={investment.rate}
            term={`${investment.term} ${investment.termSuffix}`}
            min={investment.min}
            max={investment.max}
            amount={amounts[strategy][index]}
            onChange={amount => onAmountChange(amount, index)}
            onTouchMove={() => setShowTotalInvestment(true)}
            onTouchEnd={() => setShowTotalInvestment(false)}
            color={ColumnColors[index]}
          />
        ))}
      </ControlsHolder>
    </>
  );
};

export const PortfolioSimulator: FC<PortfolioSimulatorProps> = ({
  portfolioConfig,
  withPadding,
}) => {
  const initAmount = useMemo(
    () => portfolioConfig.map(s => s.investments.map(i => i.defaultAmount)),
    [portfolioConfig]
  );
  return (
    <Background withPadding={withPadding}>
      <Typography variant="h3">Portfolio simulator</Typography>
      <Typography variant="body2" sx={{ my: 3 }}>
        See how you can benefit from building a portfolio of alternative investments.
      </Typography>
      <PortfolioChart portfolioConfig={portfolioConfig} initAmount={initAmount} />
      <Disclaimer variant="body2">
        All investments involve risk, including loss of principal.{' '}
        <a href="/simulator-disclosures/">View Disclosures</a>
      </Disclaimer>
    </Background>
  );
};
