import { pickBy } from 'lodash';
import { UserState } from '@yieldstreet/platform-kit';
import { LDFlagsUserContext, LDFlagsFeatureContext, LDFlagsContext } from './flags.model';
import { getExtraUserContext } from './getFlagsContext.utils';

export type FlagsParams = {
  anonymousId: string;
  userId?: string;
  host?: string;
  userState?: UserState;
};

export const getFlagsContext = ({
  anonymousId,
  userId,
  host,
  userState,
}: FlagsParams): LDFlagsContext => {
  const userKey = userId || anonymousId;
  const userContext: LDFlagsUserContext = {
    key: userKey,
    anonymous: !userId,
    ...getExtraUserContext(userState),
  };

  const subdomain = host?.split('.').shift();
  const featureKey = host?.endsWith('yieldstreet.cloud') ? subdomain : null;
  const featureContext: LDFlagsFeatureContext | null = featureKey ? { key: featureKey } : null;

  const context = { kind: 'multi', user: userContext, feature: featureContext };

  return pickBy(context, value => value) as LDFlagsContext;
};
