import React, { FC } from 'react';
import { kebabCase } from 'lodash';
import { Box } from '@mui/material';
import numeral from 'numeral';

import { DesktopOnly, MobileOnly } from '../../../../utils';

import { AmountSelectorProps } from './AmountSelector.model';
import {
  AmountSelectorHolder,
  AmountTitle,
  AmountValue,
  Label,
  LineValue,
  Paragraph,
  SSliderInput,
} from './AmountSelector.style';

export const AmountSelector: FC<AmountSelectorProps> = ({
  amount,
  annualizedYield,
  color,
  max = 100000,
  min = 0,
  onChange,
  onTouchEnd,
  onTouchMove,
  step = 1000,
  term,
  title,
}) => {
  return (
    <AmountSelectorHolder>
      <AmountTitle>
        <Paragraph semiBold>{title}</Paragraph>
        <AmountValue>{numeral(amount).format('$0,0')}</AmountValue>
      </AmountTitle>
      <LineValue>
        <Label variant="body2">Target annualized yield</Label>
        <Label variant="body2">
          <>{annualizedYield}%</>
        </Label>
      </LineValue>
      <LineValue>
        <Label variant="body2">Target term</Label>
        <Label variant="body2">{term}</Label>
      </LineValue>
      <Box onTouchMove={onTouchMove} onTouchEnd={onTouchEnd}>
        <MobileOnly>
          <SSliderInput
            formatValue={() => ''}
            fullWidth
            label={kebabCase(title)}
            max={max}
            min={min}
            name="slider"
            onChange={value => onChange && onChange(value)}
            step={step}
            trackColor={color}
            value={amount}
          />
        </MobileOnly>
        <DesktopOnly>
          <SSliderInput
            formatValue={value => numeral(value).format('$0,0')}
            fullWidth
            label={kebabCase(title)}
            max={max}
            min={min}
            name="slider"
            onChange={value => onChange && onChange(value)}
            step={step}
            trackColor={color}
            value={amount}
          />
        </DesktopOnly>
      </Box>
    </AmountSelectorHolder>
  );
};
