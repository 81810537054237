import React, { FunctionComponent, useEffect } from 'react';
import { CallbackLink } from './CallbackLink';
import { UserState } from '@yieldstreet/platform-kit';

interface ZendeskLinkProps {
  showForm?: boolean;
  className?: string;
}

const zendeskWatcher = () => {
  const zendeskInterval = setInterval(() => {
    const zendeskIframe = document.querySelector('iframe#launcher');

    if (!!zendeskIframe) {
      const zendeskStyle = zendeskIframe.getAttribute('style');

      zendeskIframe?.classList.add('mui-fixed');
      zendeskIframe?.setAttribute('style', `${zendeskStyle} width: auto !important`);

      clearInterval(zendeskInterval);
    }
  }, 1000);
};

export const ZendeskLink: FunctionComponent<ZendeskLinkProps> = ({
  children,
  showForm,
  className,
}) => {
  useEffect(() => {
    zendeskWatcher();
  }, []);

  return (
    <CallbackLink className={className} callback={() => zendeskLaunch(showForm)}>
      {children}
    </CallbackLink>
  );
};

// Wrapped zendesk API calls in a function since zendesk script might not be fully loaded when method is called
// See documentation here https://developer.zendesk.com/embeddables/docs/widget/api
const zendeskCall = (callback: (zE: any) => void) =>
  (window as any).zE &&
  (window as any).zE(() => {
    callback((window as any).zE);
  });

const suppressHelp = (suppress: boolean) =>
  zendeskCall(zE =>
    zE('webWidget', 'updateSettings', {
      webWidget: {
        helpCenter: {
          suppress: suppress,
        },
      },
    })
  );

export const zendeskLaunch = (showForm?: boolean) => {
  showForm && suppressHelp(true);
  zendeskCall(zE => zE.activate());
  showForm && suppressHelp(false);
};

export const zendeskIdentify = (userState: UserState) => {
  zendeskCall(zE =>
    zE.identify({
      name: userState.displayName,
      email: userState.emailAddress,
    })
  );
};

export const zendeskClear = () => zendeskCall(zE => zE('webWidget', 'clear'));
