import numeral from 'numeral';
import { OfferingStatus } from '@yieldstreet/platform-kit';

export const formatProgressPercentRemaining = (
  value: number | undefined,
  status: string
): string => {
  if (value === undefined || isNaN(value)) {
    return '';
  }
  if (value > 0 && value < 0.01 && status === OfferingStatus.open) {
    return '<1%';
  }

  switch (status) {
    case OfferingStatus.preoffering:
      return '100%';
    case OfferingStatus.pending:
    case OfferingStatus.pendingFullyCommitted:
    case OfferingStatus.payout:
    case OfferingStatus.closed:
    case OfferingStatus.repaid:
      return '0%';
    case OfferingStatus.open:
    default:
      return numeral(value).format('0%');
  }
};
