export const abbreviate = (
  number: number,
  maxPlaces = 3,
  forcePlaces = 0,
  forceLetter?: string
) => {
  if (forceLetter) {
    return annotate(number, maxPlaces, forcePlaces, forceLetter);
  }

  let abbr: string;
  if (number >= 1e12) {
    abbr = 'T';
  } else if (number >= 1e9) {
    abbr = 'B';
  } else if (number >= 1e6) {
    abbr = 'M';
  } else if (number >= 1e3) {
    abbr = 'K';
  } else {
    abbr = '';
  }

  return annotate(number, maxPlaces, forcePlaces, abbr);
};

export const annotate = (number: number, maxPlaces: number, forcePlaces: number, abbr: string) => {
  // set places to 0 to not round
  let rounded = 0;
  let roundedNumber = '';
  switch (abbr) {
    case 'T':
      rounded = number / 1e12;
      break;
    case 'B':
      rounded = number / 1e9;
      break;
    case 'M':
      rounded = number / 1e6;
      break;
    case 'K':
      rounded = number / 1e3;
      break;
    default:
      rounded = number;
      break;
  }
  if (maxPlaces) {
    var test = new RegExp('\\.\\d{' + (maxPlaces + 1) + ',}$');
    if (test.test('' + rounded)) {
      roundedNumber = rounded.toFixed(maxPlaces);
    }
  }
  roundedNumber = rounded.toFixed(forcePlaces);
  return roundedNumber + abbr;
};
