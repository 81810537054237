export const hasWindow =
  typeof window !== 'undefined' && window.document && window.document.createElement;

export const isServer = !hasWindow;
export const isBrowser = hasWindow;

export const getHost = (req?: Request) => {
  let host = '';
  if (req) {
    host = req.headers.get('host') || '';
  } else if (hasWindow) {
    host = window.location.hostname;
  }
  return host;
};

export const getIsProd = (host?: string) => {
  return host && host.indexOf('yieldstreet.com') !== -1;
};
