import React from 'react';
import { Flags, FlagsControlProps } from './flags.model';
import { useFlagsControl, useFlags } from './useFlags';

export type WithFlagsControlProps<P extends {}, T extends Flags> = P & FlagsControlProps<T>;

export const withFlagsControl = <P extends {}, T extends Flags>(
  Component: React.ComponentType<P>,
  flagsConfig: T
): React.FunctionComponent<WithFlagsControlProps<P, T>> => {
  const WrappedComponent: React.FunctionComponent<WithFlagsControlProps<P, T>> = ({ ...props }) => {
    const flagsControl = useFlagsControl(flagsConfig);
    return React.createElement(Component, {
      ...props,
      ...flagsControl,
    });
  };

  if ((Component as any).options) {
    (WrappedComponent as any).options = (Component as any).options;
  }

  return WrappedComponent;
};

export type WithFlagsProps<P extends {}, T extends Flags> = P & {
  flags: T;
};

export const withFlags = <P extends {}, T extends Flags>(
  Component: React.ComponentType<WithFlagsProps<P, T>>,
  flagsConfig: T
): React.FunctionComponent<P> => {
  const WrappedComponent: React.FunctionComponent<P> = ({ ...props }) => {
    const flags = useFlags(flagsConfig);
    return React.createElement(Component, {
      ...props,
      flags,
    });
  };

  if ((Component as any).options) {
    (WrappedComponent as any).options = (Component as any).options;
  }

  return WrappedComponent;
};
