import React, { FunctionComponent, ReactNode } from 'react';

import { Paragraph } from '../../content';

import { TextBoxWrapper, SHeading } from './TextBox.style';

interface TextBoxProps {
  title: string;
  text: string | ReactNode;
  icon?: string;
  dataCy?: string;
}

export const TextBox: FunctionComponent<TextBoxProps> = ({ title, text, icon, dataCy }) => (
  <TextBoxWrapper data-cy={dataCy || title}>
    {icon ? <img src={icon} /> : null}
    <SHeading type={4}>{title}</SHeading>
    <Paragraph size="small">{text}</Paragraph>
  </TextBoxWrapper>
);
