import { Portfolio } from '@yieldstreet/tool-kit';

export const Strategies = [
  {
    key: 0,
    title: 'Income',
    desc: 'Prioritize current income',
  },
  {
    key: 1,
    title: 'Growth',
    desc: 'Prioritize returns',
  },
  {
    key: 2,
    title: 'Balanced',
    desc: 'Blend income and growth',
  },
];

export interface ChartProps {
  initAmount: number[][];
  portfolioConfig: Portfolio[];
}

export interface PortfolioSimulatorProps {
  portfolioConfig: ChartProps['portfolioConfig'];
  withPadding?: boolean;
}
