import { useMemo, useEffect, useCallback, useContext } from 'react';
import mapValues from 'lodash/mapValues';
import forEach from 'lodash/forEach';
import { v4 as uuidv4 } from 'uuid';

import { Flags, EvalFlag, FlagsControlProps, FlagsContext } from './flags.model';
import { evalFlagComponents } from './useFlags.utils';

export const useFlagsControl = <T extends Flags>(flagsConfig: T): FlagsControlProps<T> => {
  const { allFlags, evalFlag: evalFlagExternal } = useContext(FlagsContext);

  const componentId = useMemo(() => uuidv4(), []);

  // get single flag value
  const getFlagValue = useCallback(
    (flag, defaultValue) => (flag in allFlags ? allFlags[flag] : defaultValue),
    [allFlags]
  );

  // get all flag values
  const flags = useMemo(() => {
    return mapValues(flagsConfig, (defaultValue, flag) => getFlagValue(flag, defaultValue));
  }, [flagsConfig, getFlagValue]);

  // internal evaluate flag logic
  const evalFlag: EvalFlag<any> = useCallback(
    (flag, defaultValue) => {
      const value = flags[flag as string];

      // check if flag already evaluated
      if (!evalFlagComponents.flagHasEval(flag as string)) {
        // evaluate flag
        evalFlagExternal(flag, defaultValue);
      }

      // push component reference to flag cache to track component unmount
      evalFlagComponents.addFlagComponent(flag as string, componentId);

      return value;
    },
    [evalFlagExternal, componentId, flags]
  );

  useEffect(() => {
    return () => {
      // clear result cache on unmount to make sure that when
      // component is mounted again, all flags get re-evaluated
      // only in case all components using the flag have been unmounted
      // to ensure multiple evaluations won't happen just because the
      // the flag value is being used in multiple components mounted at the same time
      forEach(flagsConfig, (_defaultValue, flag) => {
        evalFlagComponents.removeFlagComponent(flag as string, componentId);
      });
    };
    // Disabling dependencies to ensure cleanup is done only on unmount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // evaluate all flags
  const evalAllFlags = useCallback(() => {
    forEach(flagsConfig, (defaultValue, flag) => evalFlag(flag, defaultValue));
  }, [flagsConfig, evalFlag]);

  return { flags, evalFlag, evalAllFlags };
};

export const useFlags = <T extends Flags>(flagsConfig: T) => {
  const { flags, evalAllFlags } = useFlagsControl(flagsConfig);

  // evaluate all flags on render
  useEffect(() => {
    evalAllFlags();
  }, [evalAllFlags]);

  return flags;
};
