import React, { FunctionComponent } from 'react';

import { Heading, Label } from '../../content/text';

import { StatsViewProps } from './StatsView.model';
import {
  BackgroundImage,
  Container,
  LeftContainer,
  RightContainer,
  SHeading,
  SParagraph,
  SSectionContainer,
  StatItem,
  StatsWrapper,
} from './StatsView.style';

export const StatsView: FunctionComponent<StatsViewProps> = ({
  children,
  image,
  imageComponent,
  inverse,
  label,
  noMobilePadding,
  rightContainerWidth,
  stats,
  subTitle,
  title,
}) => {
  const hasImage = Boolean(imageComponent || image);

  return (
    <SSectionContainer noMobilePadding={noMobilePadding}>
      <Container>
        {hasImage && (
          <LeftContainer>
            {imageComponent ? imageComponent : <BackgroundImage image={image} />}
          </LeftContainer>
        )}
        <RightContainer width={rightContainerWidth} inverse={inverse} centerAlign={!hasImage}>
          <Heading type={2} inverse={inverse}>
            {title}
          </Heading>
          <SParagraph marginTop inverse={inverse}>
            {subTitle}
          </SParagraph>
          {stats && (
            <StatsWrapper hasImage={hasImage} test-id="stats-wrapper">
              {stats?.map(stat => (
                <StatItem data-cy="stat-item" hasImage={hasImage} key={stat.label}>
                  <SHeading inverse={inverse} type={2} tagType={3}>
                    {stat.value}
                  </SHeading>
                  <SParagraph inverse={inverse}>{stat.label}</SParagraph>
                </StatItem>
              ))}
            </StatsWrapper>
          )}
          {children}
          {label && <Label inverse={inverse}>{label}</Label>}
        </RightContainer>
      </Container>
    </SSectionContainer>
  );
};
