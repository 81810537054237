import React, { FunctionComponent, ReactNode } from 'react';

import { Paragraph } from '../../content';
import { PageSection } from '../../layout';

import {
  BannerContainer,
  ContentHolder,
  SHeading,
  ButtonContainer,
  ImageContainer,
} from './PhotoBanner.style';
interface PhotoBannerProps {
  title: string;
  content: string | ReactNode;
  image: ReactNode;
  button?: ReactNode;
  dataCy?: string;
}

export const PhotoBanner: FunctionComponent<PhotoBannerProps> = ({
  title,
  content,
  image,
  button,
  dataCy,
}) => (
  <PageSection>
    <BannerContainer data-cy={dataCy || 'photo-banner-container'}>
      <ContentHolder>
        <div>
          <SHeading type={2} inverse>
            {title}
          </SHeading>
          <Paragraph secondary inverse>
            {content}
          </Paragraph>
          {button ? <ButtonContainer>{button}</ButtonContainer> : null}
        </div>
        <ImageContainer>{image}</ImageContainer>
      </ContentHolder>
    </BannerContainer>
  </PageSection>
);
