import React, { FC } from 'react';
import { Typography } from '@mui/material';

import { ArticleCardProps } from './ArticleCard.model';
import {
  CardContainer,
  CardContent,
  CardText,
  CTAHolder,
  Description,
  Image,
  ImageWrapper,
} from './ArticleCard.style';

export const ArticleCard: FC<ArticleCardProps> = ({
  cta,
  description,
  image,
  mini,
  testId,
  title,
}) => {
  return (
    <CardContainer mini={mini} data-cy={`${testId ? testId : 'card'}`}>
      {image && (
        <ImageWrapper mini={mini}>
          <Image image={image} mini={mini} />
        </ImageWrapper>
      )}
      <CardContent>
        <CardText>
          <Typography component={mini ? 'h5' : 'h4'} variant={mini ? 'h5' : 'h4'} sx={{ mb: 1.5 }}>
            {title}
          </Typography>
          <Description mini={mini} variant="body2">
            {description}
          </Description>
          {cta && <CTAHolder>{cta}</CTAHolder>}
        </CardText>
      </CardContent>
    </CardContainer>
  );
};
