import React, { FunctionComponent } from 'react';

import { Paragraph } from '../../content/text';
import { PageSection } from '../../layout';

import {
  Container,
  ContentHolder,
  TextWrapper,
  SHeading,
  TraitItemWrapper,
  TraitItem,
  TraitIcon,
  Bubble,
  ItemHeading,
  BlocksLine,
  TraitLine,
} from './Traits.style';
interface TraitItems {
  title: string;
  text: string;
  icon?: string;
}

interface TraitsProps {
  traitItems: TraitItems[];
  title?: string;
  subtitle?: string;
  blocks?: boolean;
  dataCy?: string;
}

export const Traits: FunctionComponent<TraitsProps> = ({
  traitItems,
  title,
  subtitle,
  blocks,
  dataCy,
}) => (
  <PageSection>
    <Container data-cy={dataCy || 'traits-container'}>
      <ContentHolder>
        <TextWrapper>
          {title ? <SHeading type={2}>{title}</SHeading> : null}
          {subtitle ? <Paragraph>{subtitle}</Paragraph> : null}
        </TextWrapper>
      </ContentHolder>
      <TraitItemWrapper blocks={blocks}>
        {traitItems.map((item, index) => {
          const { title, text, icon } = item;
          return (
            <>
              <TraitItem blocks={blocks} key={`trait-${index}`}>
                {icon ? (
                  <TraitIcon src={icon} />
                ) : (
                  <Bubble>
                    <Paragraph semiBold>{`0${index + 1}`}</Paragraph>
                  </Bubble>
                )}
                <ItemHeading type={4}>{title}</ItemHeading>
                <Paragraph>{text}</Paragraph>
              </TraitItem>
              {blocks ? <BlocksLine /> : <TraitLine />}
            </>
          );
        })}
      </TraitItemWrapper>
    </Container>
  </PageSection>
);
