import React, { FunctionComponent, ReactNode } from 'react';

import { BaseInputProps } from '@yieldstreet/tool-kit';

import { Wrapper, BoxContent, SSliderInput } from './CalculatorSlider.style';
import { CalculatorHeader } from './CalculatorHeader';
import { kebabCase } from 'lodash';

interface TooltipProps {
  message: string;
  icon?: string;
}

interface CalculatorSliderProps extends BaseInputProps<number | number[]> {
  title: string;
  subtitle: string;
  tooltip?: TooltipProps;
  value: number;
  inputField?: ReactNode;
  min?: number;
  max?: number;
}

export const CalculatorSlider: FunctionComponent<CalculatorSliderProps> = ({
  title,
  subtitle,
  tooltip,
  value,
  inputField,
  min,
  max,
  onChange,
}) => {
  return (
    <Wrapper>
      <BoxContent>
        <CalculatorHeader title={title} subtitle={subtitle} tooltip={tooltip} />
        {inputField}
      </BoxContent>
      <div>
        <SSliderInput
          label={kebabCase(title)}
          name="slider"
          min={min}
          max={max}
          value={value}
          fullWidth
          onChange={value => onChange && onChange(value)}
          formatValue={() => ''}
        />
      </div>
    </Wrapper>
  );
};
