import convert from 'color-convert';
/* @ts-ignore */
import { Theme, Color, BackgroundColor } from '@adobe/leonardo-contrast-colors';

const range = (start: number, stop: number, step: number) =>
  Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step);

export const generateColors = (base: string, variantAmount: number) => {
  if (!base) {
    return [];
  }

  //if there is one color only to show, return the base color
  if (variantAmount === 1) {
    return [base];
  }

  const maxRatioContrast = 15;
  const minRatioContrast = 1.5;

  //limiting the amount to 15 due de fact that colors with ratios closed to it is too dark
  const ratiosAmount = variantAmount > maxRatioContrast ? maxRatioContrast : variantAmount;

  const step = (maxRatioContrast - minRatioContrast) / (ratiosAmount - 1);
  const ratios = range(minRatioContrast, maxRatioContrast, step);

  const baseColor = new Color({
    name: 'base',
    colorKeys: [base],
    ratios,
  });

  const whiteBg = new BackgroundColor({
    name: 'whiteBg',
    colorKeys: ['#ffffff'],
    ratios: [minRatioContrast, maxRatioContrast],
  });

  const theme = new Theme({
    backgroundColor: whiteBg,
    colors: [baseColor],
    lightness: 100,
  });

  return theme.contrastColorValues;
};

const getColorAdjustment = (saturation: number, lightness: number) => {
  return {
    newSaturation: saturation,
    newLightness: lightness,
  };
};

const generateColor = (variation: number, saturation: number, ligthness: number) => {
  switch (variation) {
    case 100:
      return getColorAdjustment(saturation - 20, ligthness + 18);
    case 200:
      return getColorAdjustment(saturation - 15, ligthness + 12);
    case 300:
      return getColorAdjustment(saturation - 10, ligthness + 6);
    case 400:
      return getColorAdjustment(saturation - 10, ligthness - 5);
    case 500:
      return getColorAdjustment(saturation - 10, ligthness - 10);
    case 600:
      return getColorAdjustment(saturation - 10, ligthness - 15);
    default:
      return getColorAdjustment(saturation, ligthness);
  }
};

export const generateColorVariation = (color: string, variation: number) => {
  const hslValue = convert.hex.hsl(color) || [];
  const [hue, saturation, lightness] = hslValue;
  const { newSaturation, newLightness } = generateColor(variation, saturation, lightness);
  const newColor = convert.hsl.hex([hue, newSaturation, newLightness]);
  return '#' + newColor;
};
