import React, { FC } from 'react';

import { abbreviate } from './Currency.util';
import { CurrencyProps } from './Currency.model';

export const Currency: FC<CurrencyProps> = ({
  code = '$',
  doNotRound,
  forcePlaces = 0,
  maxPlaces,
  postSign,
  value,
}) => {
  return (
    <span>
      {code}
      {doNotRound
        ? value.toLocaleString('en-US', { minimumFractionDigits: forcePlaces })
        : abbreviate(value, maxPlaces, forcePlaces)}
      {postSign}
    </span>
  );
};
