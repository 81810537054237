import { parse } from 'query-string';
import get from 'lodash/get';
import pick from 'lodash/pick';

interface ParamsProps {
  [key: string]: string;
}

export const defaultTrackingParams = [
  'gclid',
  'campaign_id',
  'ad_id',
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_term',
  'utm_content',
];

export const parseQueryTrackingParams = (
  queryString: string,
  selectedParams: string[] = defaultTrackingParams
) => {
  const params = parse(queryString);
  return pick(params, selectedParams);
};

export const storeTrackingMeta = (trackingParams: ParamsProps) => {
  Object.keys(trackingParams).forEach((paramKey: string) => {
    const paramValue = get(trackingParams, [paramKey], '');

    try {
      localStorage.setItem(paramKey, paramValue);
      return true;
    } catch (exception) {
      return false;
    }
  });
};

type KeyValuePair = { key: string; value: string };

const getMeta = (trackingParams: string[]): KeyValuePair[] => {
  const trackingMeta: KeyValuePair[] = [];

  trackingParams.forEach((param: string) => {
    try {
      const value = localStorage.getItem(param);
      if (value && value.length) {
        trackingMeta.push({ key: param, value });
      }
      return true;
    } catch (exception) {
      return false;
    }
  });

  return trackingMeta;
};

export const getTrackingMeta = (customParams: string[] = defaultTrackingParams) =>
  getMeta(customParams);
