import React, { FC } from 'react';

import { PageSection } from '../../layout';

import { HeroBannerProps } from './HeroBanner.model';
import {
  Container,
  Content,
  ContentHolder,
  Headline,
  InnerContainer,
  Media,
  Middle,
  OuterContainer,
  Paragraph,
  SideContent,
  Slogan,
  SloganText,
  SSectionContainer,
} from './HeroBanner.style';

const getTitleVariant = (bannerSize: string, headingType?: number) => {
  if (headingType) {
    return `h${headingType}`;
  }

  return bannerSize === 'small' ? 'h3' : 'h1';
};

export const HeroBanner: FC<HeroBannerProps> = ({
  bannerSize = 'normal',
  children,
  className,
  contentAlignment = 'left',
  headingTagType,
  headingType,
  hideMediaOnMobile,
  image,
  inverse,
  reverse,
  sideContent,
  slogan,
  tag,
  testId,
  title,
  video,
}) => {
  return (
    <OuterContainer className={className} slogan={slogan} data-cy={testId}>
      <PageSection>
        <SSectionContainer>
          <InnerContainer contentAlignment={contentAlignment}>
            <Container reverse={reverse} contentAlignment={contentAlignment}>
              <Middle
                bannerSize={bannerSize}
                contentAlignment={contentAlignment}
                className="hero-banner-content-wrapper"
              >
                <ContentHolder
                  className="hero-banner-content-holder"
                  contentAlignment={contentAlignment}
                >
                  {tag && (
                    <Paragraph
                      className="hero-banner-content-tag"
                      inverse={inverse}
                      semiBold
                      variant="body1"
                    >
                      {tag}
                    </Paragraph>
                  )}
                  {title && (
                    <Headline
                      component={headingTagType ? `h${headingTagType}` : 'h1'}
                      // @ts-expect-error - FIX ME
                      variant={getTitleVariant(bannerSize, headingType)}
                      contentAlignment={contentAlignment}
                      data-cy={`${testId}-title`}
                      inverse={inverse}
                    >
                      {title}
                    </Headline>
                  )}

                  <Content>{children}</Content>
                </ContentHolder>
              </Middle>
              {sideContent && (
                <SideContent
                  contentAlignment={contentAlignment}
                  className="hero-banner-side-content"
                >
                  {sideContent}
                </SideContent>
              )}
              {!!video && <Media hideMediaOnMobile={hideMediaOnMobile}>{video}</Media>}
              {!!image && (
                <Media hideMediaOnMobile={hideMediaOnMobile} className="hero-banner-image">
                  {image}
                </Media>
              )}
            </Container>
          </InnerContainer>
          {slogan && (
            <Slogan>
              <SloganText>{slogan}</SloganText>
            </Slogan>
          )}
        </SSectionContainer>
      </PageSection>
    </OuterContainer>
  );
};
