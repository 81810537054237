import React, { FunctionComponent } from 'react';

import { Paragraph, Label } from '../../../content';
import { Tooltip } from '../../tooltip/Tooltip';

import { TextContent, HeaderContent } from './CalculatorHeader.style';

interface TooltipProps {
  message: string;
  icon?: string;
}

interface CalculatorHeaderProps {
  title: string;
  subtitle: string;
  tooltip?: TooltipProps;
}

export const CalculatorHeader: FunctionComponent<CalculatorHeaderProps> = ({
  title,
  subtitle,
  tooltip,
}) => (
  <TextContent className="calculatorHeader">
    <HeaderContent>
      <Paragraph semiBold>{title}</Paragraph>
      {tooltip ? (
        <div>
          <Tooltip testId="calculator-header-tooltip" customIcon={tooltip?.icon}>
            {tooltip.message}
          </Tooltip>
        </div>
      ) : null}
    </HeaderContent>
    <Label>{subtitle}</Label>
  </TextContent>
);
