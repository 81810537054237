import React from 'react';

import { SwitchSelectorProps } from './SwitchSelector.model';
import { OuterContainer, Label, TabButton } from './SwitchSelector.style';

export const SwitchSelector = ({ activeKey, onClick, tabs }: SwitchSelectorProps) => {
  return (
    <OuterContainer>
      {tabs?.map((tab, key) => (
        <TabButton
          key={key}
          active={tab.key === activeKey}
          onClick={() => {
            onClick && onClick(tab);
          }}
        >
          {tab.icon ? tab.icon : ''}
          {tab.title ? (
            <Label semiBold variant="body2">
              {tab.title}
            </Label>
          ) : (
            ''
          )}
        </TabButton>
      ))}
    </OuterContainer>
  );
};
