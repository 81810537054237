import React, { FC } from 'react';

import CloseIcon from '../../assets/graphics/icons/close.svg';
import CloseIconWhite from '../../assets/graphics/icons/close_white.svg';
import { PageSection } from '../../layout';
import { SidePhotoContentProps, testIds } from './SidePhotoContent.model';
import {
  Close,
  Container,
  Content,
  ImageContainer,
  MainImage,
  RightContainer,
  SplashImage,
  TextContainer,
  Title,
} from './SidePhotoContent.style';

export const SidePhotoContent: FC<SidePhotoContentProps> = ({
  backgroundImage,
  children,
  dismiss,
  imageBorder,
  imagePosition,
  inverse,
  mainImage,
  removeFooterSpacing,
  removeHeaderSpacing,
  removeImageMargin,
  removePaddingSide,
  reverseMobile,
  small,
  splashImage,
  title,
}) => {
  return (
    <PageSection>
      <Container
        reverseMobile={reverseMobile}
        imagePosition={imagePosition}
        removeFooterSpacing={removeFooterSpacing}
        removeHeaderSpacing={removeHeaderSpacing}
        removePaddingSide={removePaddingSide}
        backgroundImage={backgroundImage}
      >
        <TextContainer
          removePaddingSide={removePaddingSide}
          small={small}
          imagePosition={imagePosition}
        >
          <Title
            inverse={inverse}
            sx={{ m: 5, mr: { md: 0 } }}
            variant={small ? 'h4' : 'h3'}
            {...testIds.title.attr}
          >
            {title}
          </Title>
          <Content {...testIds.content.attr}>{children}</Content>
        </TextContainer>
        <RightContainer removeImageMargin={removeImageMargin} small={small}>
          <ImageContainer>
            {!backgroundImage && (
              <>
                <MainImage imageBorder={imageBorder} {...testIds.mainImage.attr}>
                  {mainImage}
                </MainImage>
                {splashImage && (
                  <SplashImage imagePosition={imagePosition} {...testIds.splashImage.attr}>
                    {splashImage}
                  </SplashImage>
                )}
              </>
            )}
          </ImageContainer>
        </RightContainer>
        {dismiss && <Close src={inverse ? CloseIconWhite : CloseIcon} onClick={dismiss} />}
      </Container>
    </PageSection>
  );
};
