import React from 'react';

import { StrategySelectorProps } from './StrategySelector.model';
import { Container, Label, OuterContainer, Paragraph, Strategy } from './StrategySelector.style';

export const StrategySelector = ({ options, activeKey, onClick }: StrategySelectorProps) => {
  return (
    <OuterContainer>
      <Container>
        {options?.map((option, key) => (
          <Strategy
            key={key}
            active={option.key === activeKey}
            onClick={() => {
              onClick && onClick(option);
            }}
          >
            <Paragraph variant="body1" semiBold>
              {option.title}
            </Paragraph>
            <Label variant="body2" semiBold>
              {option.desc}
            </Label>
          </Strategy>
        ))}
      </Container>
    </OuterContainer>
  );
};
