import React, { FunctionComponent } from 'react';
import { PageRendererProps } from 'gatsby';
import { SimpleHeader, Footer } from '@yieldstreet/ui-kit';
import { renderLink } from 'components/ui/Link';
import { PageContext } from '../MainLayout/MainLayout';

export interface SimpleLayoutProps extends Partial<PageRendererProps> {
  hideLogin?: boolean;
  hideSignup?: boolean;
  hideHeader?: boolean;
  hideFooter?: boolean;
}

const SimpleLayout: FunctionComponent<SimpleLayoutProps> = ({
  location,
  children,
  hideLogin,
  hideSignup,
  hideHeader,
  hideFooter,
}) => {
  return (
    <PageContext.Provider value={{ location }}>
      {!hideHeader ? (
        <SimpleHeader hideLogin={hideLogin} hideSignup={hideSignup} logoAlt="Yieldstreet" />
      ) : null}
      {children}
      {!hideFooter ? <Footer renderLink={renderLink} staticPage condensed /> : null}
    </PageContext.Provider>
  );
};

export default SimpleLayout;
