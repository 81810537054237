import React, { useState, useRef, useEffect, Ref } from 'react';
import ReactTooltip from 'react-tooltip';

import closeIconImg from '../../assets/graphics/icons/closeGrey.svg';
import { useOutsideClick } from '../../hooks/use-outside-click';

import tooltipDefault from './assets/tooltip-default.svg';
import tooltipActive from './assets/tooltip-active.svg';
import { TooltipProps, useTestIds } from './Tooltip.model';
import { CloseIcon, StyledReactTooltip, TooltipContent, TooltipDiv } from './Tooltip.style';
import { BaseProps } from '../../types';

export const Tooltip = ({
  buttonType = 'submit',
  children,
  closeIcon,
  customIcon,
  customIconHeight,
  customIconWidth,
  inverse,
  place,
  clickable = true,
  tooltipKey,
  ...props
}: TooltipProps & BaseProps) => {
  const [isShowing, setShowing] = useState(false);
  const [hasLoaded, setHasLoaded] = useState(false);
  const wrapperRef = useRef(null);
  const tooltipRef = useRef<ReactTooltip & { tooltipRef: Ref<HTMLElement> }>(null!);

  useOutsideClick(wrapperRef, setShowing);

  useEffect(() => {
    setHasLoaded(true);
  }, []);

  const handleClose = () => {
    if (tooltipRef?.current && 'tooltipRef' in tooltipRef.current) {
      tooltipRef.current.tooltipRef = null;
    }
    ReactTooltip.hide();
  };

  useEffect(() => {
    window.addEventListener('scroll', handleClose);
    return () => {
      window.removeEventListener('scroll', handleClose);
    };
  }, []);

  let iconSrc = isShowing ? tooltipActive : tooltipDefault;
  if (customIcon) {
    iconSrc = customIcon;
  }

  const testIds = useTestIds(props);

  return (
    <TooltipDiv
      data-for={tooltipKey}
      data-tip
      onClick={e => (closeIcon ? e.preventDefault() : {})}
      ref={wrapperRef}
      type={buttonType}
      {...testIds.base.attr}
    >
      <img
        data-js-tooltip-icon
        alt="tooltip"
        onClick={() => {
          setShowing(true);
        }}
        width={!customIcon ? '15' : customIconWidth}
        height={!customIcon ? '15' : customIconHeight}
        src={iconSrc}
      />
      {hasLoaded && (
        <StyledReactTooltip
          afterHide={() => setShowing(false)}
          afterShow={() => setShowing(true)}
          border
          clickable={clickable}
          delayHide={100}
          delayShow={100}
          delayUpdate={100}
          effect="solid"
          id={tooltipKey}
          inverse={inverse}
          place={place}
          ref={tooltipRef}
          type="light"
          getContent={() => (
            <TooltipContent {...testIds.content.attr}>
              {children}
              {closeIcon && (
                <TooltipDiv onClick={handleClose}>
                  <CloseIcon src={closeIconImg} />
                </TooltipDiv>
              )}
            </TooltipContent>
          )}
        />
      )}
    </TooltipDiv>
  );
};
