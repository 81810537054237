import { OfferingStatus, OfferingV5 } from '@yieldstreet/platform-kit';

import { isFortyActFund, isFortyActOrRegAPlus } from '../offering';
import { formatProgressPercentRemaining } from '../formatters/formatProgressPercentRemaining';

export type LabelTagConfig = {
  text?: string;
  textColor?: 'green' | 'white' | 'red' | 'navy' | 'darkGray' | 'deepBlue';
  backgroundColor?:
    | 'lightGreen'
    | 'lightTeal'
    | 'green'
    | 'blue'
    | 'lightRed'
    | 'deepBlue'
    | 'lightDeepBlue'
    | 'black'
    | 'gray';
  icon?:
    | 'flame'
    | 'update'
    | 'preferredAssetClassLabel'
    | 'checkCircle'
    | 'new'
    | 'always'
    | 'launching';
  testingID?: string;
};

export type OfferingProgress = {
  success: boolean;
  noteUrlHash: string;
  noteAmount: number;
  requestedAmount: number;
  remainingInvestment: number;
  remainingPercentage: number;
  fundingProgress: number;
  offeringStatus: OfferingStatus;
  uniqueInvestorCount: number;
  segmented: boolean;
};

export const getLabelTagConfig = (
  offering: OfferingV5,
  progress?: OfferingProgress
): LabelTagConfig[] => {
  const { showNewLabel, progressMessage, newInvestorsOnly, segmented, segment } = offering;
  const status = progress && progress.offeringStatus ? progress.offeringStatus : offering.status;

  const labelTagArr: LabelTagConfig[] = [];

  const isNew = showNewLabel;
  const isOpenOffer = status === OfferingStatus.open;
  const isUpcomingOffer = status === OfferingStatus.preoffering;
  const requestedAmount = progress?.requestedAmount || 0;
  const noteAmount = progress?.noteAmount || 0;
  const availability = requestedAmount / noteAmount;
  const isWaitlistOpen = status === OfferingStatus.open && availability >= 1;
  const isSegmented = segmented && segment;
  const segmentName = isSegmented ? segment?.name : null;
  const isFullyAllocated = status === OfferingStatus.pending;
  const rawPercentage = (progress?.remainingInvestment || 0) / noteAmount;
  const remainingPercentageFormatted = formatProgressPercentRemaining(rawPercentage, status || '');
  const updatedDate = offering?.updatedDate?.date;

  const isPrismOrReit = isFortyActOrRegAPlus(offering?.fundType);

  const isAlwaysOpen = isPrismOrReit && isOpenOffer && !isWaitlistOpen;

  if (isAlwaysOpen) {
    labelTagArr.push({
      text: 'Always open',
      textColor: 'white',
      backgroundColor: 'blue',
      icon: 'always',
    });
  }

  if (isWaitlistOpen) {
    labelTagArr.push({
      text: 'Waitlist open',
      textColor: 'white',
      backgroundColor: 'blue',
    });
  }

  if (isNew) {
    labelTagArr.push({
      text: 'New',
      textColor: 'white',
      backgroundColor: 'green',
      icon: 'new',
    });
  }

  if (
    !isFortyActFund(offering?.fundType) &&
    isOpenOffer &&
    !isWaitlistOpen &&
    rawPercentage <= 0.1 &&
    !isAlwaysOpen
  ) {
    labelTagArr.push({
      text: `${remainingPercentageFormatted} remaining`,
      textColor: 'red',
      backgroundColor: 'lightRed',
      icon: 'flame',
      testingID: 'remaining percentage',
    });
  }

  if (updatedDate) {
    labelTagArr.push({
      text: updatedDate,
      textColor: 'navy',
      backgroundColor: 'lightDeepBlue',
      icon: 'update',
      testingID: 'updated date',
    });
  }

  // We should replace 'progressMessage' by the launchDate field in here as soon as it is added to the marketplace endpoint.
  if (isUpcomingOffer && progressMessage) {
    labelTagArr.push({
      text: progressMessage,
      textColor: 'green',
      backgroundColor: 'lightGreen',
      testingID: 'upcoming',
      icon: 'launching',
    });
  }

  if (newInvestorsOnly) {
    labelTagArr.push({
      text: 'First time investors',
      textColor: 'white',
      backgroundColor: 'deepBlue',
    });
  }

  if (isSegmented && segmentName) {
    labelTagArr.push({
      text: segmentName,
      textColor: 'white',
      backgroundColor: 'deepBlue',
      testingID: 'segment',
    });
  }

  if (isFullyAllocated) {
    labelTagArr.push({
      text: 'Fully allocated',
      textColor: 'white',
      backgroundColor: 'black',
    });
  }
  return labelTagArr;
};
