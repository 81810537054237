import { NavigationItem } from '../../types/components/navigation';

interface FooterMenu {
  heading: string;
  menu: NavigationItem[];
}

export const FOOTER_NAVIGATION: FooterMenu[] = [
  {
    heading: 'Investors',
    menu: [
      {
        key: 'invest',
        title: 'Offerings',
        route: '/offerings/',
      },
      {
        key: 'how-it-works',
        title: 'How it works',
        route: '/how-it-works/',
        staticRoute: true,
      },
      {
        key: 'alternative-income-fund',
        title: 'Alternative Income Fund',
        route: 'https://yieldstreetalternativeincomefund.com',
        forceExternal: true,
        newTab: true,
      },
      {
        key: 'ys-ira',
        title: 'IRA accounts',
        route: '/ira/',
        staticRoute: true,
      },
      {
        key: 'short-term-notes',
        title: 'Short Term Notes',
        route: '/short-term-notes/',
        staticRoute: true,
      },
      {
        key: 'real-estate-investing',
        title: 'Real Estate',
        route: '/real-estate-investing/',
        staticRoute: true,
      },
      {
        key: 'art-investing',
        title: 'Art',
        route: '/investing-in-art/',
        staticRoute: true,
      },
    ],
  },
  {
    heading: 'Originators',
    menu: [
      {
        key: 'raise-capital',
        title: 'Raise capital',
        route: '/raise-capital/',
        staticRoute: true,
      },
      {
        key: 'yieldstreet-real-estate',
        title: 'Real Estate',
        route: '/raise-capital/real-estate/',
        staticRoute: true,
      },
      {
        key: 'yieldstreet-art',
        title: 'Art',
        route: '/raise-capital/art/',
        staticRoute: true,
      },
      {
        key: 'private-business-credit',
        title: 'Private Business Credit',
        route: '/raise-capital/private-business-credit/',
        staticRoute: true,
      },
      {
        key: 'yieldstreet-legal',
        title: 'Legal',
        route: '/raise-capital/legal/',
        staticRoute: true,
      },
    ],
  },
  {
    heading: 'Resources',
    menu: [
      {
        key: 'contact',
        title: 'Contact us',
        id: 'intercom-link',
      },
      {
        key: 'support',
        title: 'Support',
        route: '/support/',
      },
      {
        key: 'education',
        title: 'Insight and education',
        route: '/articles/',
      },
      {
        key: 'performance',
        title: 'Performance',
        route: '/statistics/',
        staticRoute: true,
      },
    ],
  },
  {
    heading: 'Company',
    menu: [
      {
        key: 'about',
        title: 'About us',
        route: '/about/',
        staticRoute: true,
      },
      {
        key: 'careers',
        title: 'Careers',
        route: '/careers/',
        staticRoute: true,
      },
      {
        key: 'press',
        title: 'Press',
        route: '/press/',
      },
    ],
  },
];
