import React, { FunctionComponent } from 'react';

import { MarketingContainer } from '../../layout';
import { TextBlock } from '../../sections/text-block-component';

import { Wrapper, CarouselContainer, TextWrapper } from './MarketingSideCarouselContent.styles';

import { MarketingSideCarouselContentProps } from './MarketingSideCarouselContent.model';

export const MarketingSideCarouselContent: FunctionComponent<MarketingSideCarouselContentProps> = ({
  carousel,
  reverse,
  label,
  heading,
  content,
  button,
  mobileAlign = 'start',
}) => {
  return (
    <MarketingContainer>
      <Wrapper reverse={reverse}>
        <CarouselContainer>{carousel}</CarouselContainer>
        <TextWrapper>
          <TextBlock
            label={label}
            heading={heading}
            headingType={2}
            content={content}
            align="start"
            mobileAlign={mobileAlign}
            button={button}
          />
        </TextWrapper>
      </Wrapper>
    </MarketingContainer>
  );
};
