import { UserMetaData, UserState } from '@yieldstreet/platform-kit';

const excludedKeys: string[] = [
  'ad_id',
  'intercom_user_hash_web',
  'intercom_user_hash_android',
  'intercom_user_hash_ios',
  'signup_quiz_id',
  'campaign_id',
  'segment_anonymous_id',
];

const buildMetaData = (userMetaData: UserMetaData) => {
  const metaKeys = Object.entries(userMetaData);
  const filteredMetaData = metaKeys.filter(([key]) => !excludedKeys.includes(key));

  return Object.fromEntries(filteredMetaData);
};

const getUserMetaData = (userMetaData?: UserMetaData) => {
  if (!userMetaData) {
    return {};
  }

  return { meta: buildMetaData(userMetaData) };
};

/**
 * ensure that the date is in the correct format "RFC-3339"
 * https://docs.launchdarkly.com/sdk/concepts/flag-types#representing-datetime-values
 */

const formatDate = (date?: string | number) => date && new Date(date).toISOString();

export const getExtraUserContext = (userState?: UserState) => {
  if (!userState) {
    return {};
  }

  return {
    ...getUserMetaData(userState?.userMeta),
    createdAt: formatDate(userState?.createdAt),
  };
};
