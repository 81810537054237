export const BadgeIcons = {
  FLAME: 'flame',
  UPDATE: 'update',
  NEW: 'new',
  ALWAYS: 'always',
  LAUNCHING: 'launching',
};

export type BadgeIcons = (typeof BadgeIcons)[keyof typeof BadgeIcons];

export type BadgeConfig = {
  text?: string;
  icon?: BadgeIcons;
  testingID?: string;
};
