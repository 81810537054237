import React, { FC, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

import { AccordionProps, mapToTestIds } from './Accordion.model';
import {
  AccordionHeading,
  AnimatedContent,
  Container,
  Description,
  HeadingIcon,
  HeadingWrapper,
  IconHolder,
  Title,
} from './Accordion.style';

export const Accordion: FC<AccordionProps> = ({
  addParagraphMargin = true,
  animated,
  background = 'primary',
  children,
  headingIcon,
  headingType = 4,
  showIcon = true,
  startOpen,
  subHeading,
  tagType = 4,
  title,
  testId,
  toggleVisibilityCallback,
}) => {
  const [show, setShow] = useState<boolean>(startOpen || false);

  const toggleShow = () => {
    setShow(!show);
    if (toggleVisibilityCallback) {
      toggleVisibilityCallback();
    }
  };

  const testIds = mapToTestIds(testId);

  return (
    <Container background={background} {...testIds.base.attr}>
      <Title onClick={toggleShow}>
        <HeadingWrapper>
          <AccordionHeading type={headingType} tagType={tagType}>
            {headingIcon && <HeadingIcon src={headingIcon} />}

            {title}
          </AccordionHeading>
          {subHeading}
        </HeadingWrapper>
        {showIcon && (
          <>
            <IconHolder>
              {show && <FontAwesomeIcon icon={faChevronUp} />}
              {!show && <FontAwesomeIcon icon={faChevronDown} />}
            </IconHolder>
          </>
        )}
      </Title>
      {animated && (
        <AnimatedContent show={show}>
          <Description addParagraphMargin={addParagraphMargin}>{children}</Description>
        </AnimatedContent>
      )}
      {!animated && show && (
        <Description addParagraphMargin={addParagraphMargin}>{children}</Description>
      )}
    </Container>
  );
};
