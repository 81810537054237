import { AccreditationType, User2FAMethod } from '@yieldstreet/platform-kit';
import {
  ContentCardsPlaceholderPage,
  ContentCardsPlaceholderPlatform,
  ContentCardsPlaceholderPosition,
} from '../content-cards';
import { EvalFlagResult } from '../flags';

export enum AuthType {
  PASSWORD = 'password',
  GOOGLE = 'google',
  APPLE = 'apple',
}

export enum AccreditationStatus {
  ACCREDITED = 'accredited',
  NON_ACCREDITED = 'non-accredited',
}

export type BaseEventProps = {
  [key: string]: any;
};

export type EventMap<Event extends string, Map extends Record<Event, BaseEventProps>> = Map;

export enum BusinessEvent {
  USER_FLAG_EVALUATED = 'user.flag.evaluated',
  USER_SIGNUP_STARTED = 'user.signup.started',
  USER_SIGNUP_ACCREDITATION = 'user.signup.accreditation',
  USER_LOGIN_STARTED = 'user.login.started',
  USER_LOGIN_LINK_SOCIAL = 'user.login.linkSocial',
  USER_LOGIN_VERIFY_2FA = 'user.login.verify2FA',
  USER_LOGIN_REGISTER_2FA = 'user.login.register2FA',
  USER_LOGIN_CONFIRM_2FA = 'user.login.confirm2FA',
  USER_LOGIN_RESEND_2FA = 'user.login.resend2FA',
  USER_LOGIN_RESET_PASSWORD = 'user.login.resetPassword',
  USER_LOGIN_SET_PASSWORD = 'user.login.setPassword',
  USER_LOGOUT_STARTED = 'user.logout.started',
  USER_PORTFOLIO_COMPOSITION_ASSET_CLASS = 'user.portfolio.composition.assetClass',
  USER_PORTFOLIO_UPCOMING_MATURITIES_INVESTMENT = 'user.portfolio.upcoming.maturities.investment',
  USER_PORTFOLIO_UPCOMING_MATURITIES_PROMOTIONAL = 'user.portfolio.upcoming.maturities.promotional',
  USER_ACTIVITY_ITEM_QPS_CLICK = 'user.activity.item.qps.click',
  USER_ACTIVITY_QPS_VIEW_MODAL = 'user.activity.qps.view.modal',
  USER_ACTIVITY_QPS_VIEW_SLIDE = 'user.activity.qps.view.slide',
  USER_ACTIVITY_QPS_SLIDE_ACTION = 'user.activity.qps.slide.action',
  USER_CAMPAIGN_VOTE = 'user.campaign.vote',
  USER_COMMS_PLATFORM_PROMO_VIEW = 'user.communications.platformPromo.view',
  USER_COMMS_PLATFORM_PROMO_DISMISS = 'user.communications.platformPromo.dismiss',
  USER_COMMS_PLATFORM_PROMO_ACTION = 'user.communications.platformPromo.action',
  INVESTOR_CREATION_SUBMITTED = 'investorAccount.accountCreation.submitted',
  INVESTOR_IDENTITY_SUBMITTED = 'investorAccount.status.id.submitted',
  INVESTOR_BANK_SUBMITTED = 'investorAccount.status.bank.submitted',
  INVESTOR_ACCREDITATION_SUBMITTED = 'investor.accreditation.submitted',
  INVESTOR_INVESTMENT_AMOUNT = 'investor.investment.amount',
  USER_PROMOCARD_VIEWED = 'user.promoCard.viewed',
  USER_PROMOCARD_CLICKED = 'user.promoCard.clicked',
  USER_PROMOCARD_DISMISSED = 'user.promoCard.dismissed',
  USER_PROMOCARD_PINNED = 'user.promoCard.pinned',
}

export type BusinessEventMap = EventMap<
  BusinessEvent,
  {
    [BusinessEvent.USER_FLAG_EVALUATED]: EvalFlagResult<any, any>;
    [BusinessEvent.USER_SIGNUP_STARTED]: { type: AuthType };
    [BusinessEvent.USER_SIGNUP_ACCREDITATION]: { accredited: boolean };
    [BusinessEvent.USER_LOGIN_STARTED]: { type: AuthType; require2FA: boolean };
    [BusinessEvent.USER_LOGIN_LINK_SOCIAL]: { require2FA: boolean };
    [BusinessEvent.USER_LOGIN_VERIFY_2FA]: { authMethod: User2FAMethod };
    [BusinessEvent.USER_LOGIN_REGISTER_2FA]: { authMethod: User2FAMethod };
    [BusinessEvent.USER_LOGIN_CONFIRM_2FA]: { authMethod: User2FAMethod };
    [BusinessEvent.USER_LOGIN_RESEND_2FA]: { authMethod: User2FAMethod };
    [BusinessEvent.USER_LOGIN_RESET_PASSWORD]: {};
    [BusinessEvent.USER_LOGIN_SET_PASSWORD]: {};
    [BusinessEvent.USER_LOGOUT_STARTED]: {};
    [BusinessEvent.USER_PORTFOLIO_COMPOSITION_ASSET_CLASS]: {
      assetClass: string;
      hasInvestment: boolean;
    };
    [BusinessEvent.USER_PORTFOLIO_UPCOMING_MATURITIES_INVESTMENT]: {
      offeringTitle: string;
    };
    [BusinessEvent.USER_PORTFOLIO_UPCOMING_MATURITIES_PROMOTIONAL]: {
      offeringTitle: string;
    };
    [BusinessEvent.USER_ACTIVITY_ITEM_QPS_CLICK]: {};
    [BusinessEvent.USER_ACTIVITY_QPS_VIEW_MODAL]: {};
    [BusinessEvent.USER_ACTIVITY_QPS_VIEW_SLIDE]: {
      slide: string;
    };
    [BusinessEvent.USER_ACTIVITY_QPS_SLIDE_ACTION]: {
      slide: string;
      action: string;
    };
    [BusinessEvent.USER_CAMPAIGN_VOTE]: {};
    [BusinessEvent.USER_COMMS_PLATFORM_PROMO_VIEW]: {
      name: string;
      location: string;
      type: string;
    };
    [BusinessEvent.USER_COMMS_PLATFORM_PROMO_DISMISS]: {
      name: string;
      location: string;
      type: string;
    };
    [BusinessEvent.USER_COMMS_PLATFORM_PROMO_ACTION]: {
      name: string;
      location: string;
      type: string;
      cta?: string;
    };
    [BusinessEvent.INVESTOR_CREATION_SUBMITTED]: {
      step: 'name' | 'address' | 'phone' | 'details';
    };
    [BusinessEvent.INVESTOR_IDENTITY_SUBMITTED]: {
      document: string;
    };
    [BusinessEvent.INVESTOR_BANK_SUBMITTED]: {
      step?: 'manual' | 'login' | 'mfa';
    };
    [BusinessEvent.INVESTOR_ACCREDITATION_SUBMITTED]: {
      type: AccreditationType;
    };
    [BusinessEvent.INVESTOR_INVESTMENT_AMOUNT]: {
      amount: number;
      urlHash: string;
      noteTitle: string;
    };
    [BusinessEvent.USER_PROMOCARD_VIEWED]: {
      id: string;
      title: string;
      imageUrl: string;
      url: string;
      page: ContentCardsPlaceholderPage;
      position: ContentCardsPlaceholderPosition;
      platform?: ContentCardsPlaceholderPlatform;
    };
    [BusinessEvent.USER_PROMOCARD_CLICKED]: {
      id: string;
      title: string;
      imageUrl: string;
      url: string;
      page: ContentCardsPlaceholderPage;
      position: ContentCardsPlaceholderPosition;
      platform?: ContentCardsPlaceholderPlatform;
    };
    [BusinessEvent.USER_PROMOCARD_DISMISSED]: {
      id: string;
      title: string;
      imageUrl: string;
      url: string;
      page: ContentCardsPlaceholderPage;
      position: ContentCardsPlaceholderPosition;
      platform?: ContentCardsPlaceholderPlatform;
    };
    [BusinessEvent.USER_PROMOCARD_PINNED]: {
      id: string;
      title: string;
      imageUrl: string;
      url: string;
      page: ContentCardsPlaceholderPage;
      position: ContentCardsPlaceholderPosition;
      platform?: ContentCardsPlaceholderPlatform;
    };
  }
>;

export enum SegmentSpecEvent {
  SIGNED_UP = 'Signed Up',
  SIGNED_UP_ACCREDITED = 'Signed Up Accredited',
  SIGNED_IN = 'Signed In',
  PRODUCT_VIEWED = 'Product Viewed',
  PRODUCT_LIST_VIEWED = 'Product List Viewed',
  ORDER_COMPLETED = 'Order Completed',
  FIRST_INVESTMENT = 'First Investment',
}

export type SegmentSpecEventMap = EventMap<
  SegmentSpecEvent,
  {
    [SegmentSpecEvent.SIGNED_UP]: {
      type: AccreditationStatus;
    };
    [SegmentSpecEvent.SIGNED_UP_ACCREDITED]: {};
    [SegmentSpecEvent.SIGNED_IN]: {};
    [SegmentSpecEvent.PRODUCT_VIEWED]: {
      urlHash: string;
      title: string;
    };
    [SegmentSpecEvent.PRODUCT_LIST_VIEWED]: {
      searchFilters: Object;
      offeringList: string[];
    };
    [SegmentSpecEvent.ORDER_COMPLETED]: {
      orderId: string;
      total: number;
      currency: string;
      noteUrlHash: string;
    };
    [SegmentSpecEvent.FIRST_INVESTMENT]: {
      investmentId: string;
      total: number;
      currency: string;
      noteUrlHash: string;
    };
  }
>;

export type TrackingEventMap = BusinessEventMap & SegmentSpecEventMap;

export type TrackingEvent = keyof TrackingEventMap;
