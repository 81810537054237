import React from 'react';
import styled from 'styled-components';
import { Link as RouterLink } from 'gatsby';

import { LinkStyles, NavigationLinkProps } from '@yieldstreet/ui-kit';

export const Link = styled(RouterLink)`
  ${LinkStyles}
`;

export const renderLink = ({ navItem, raw, inverse }: NavigationLinkProps) => {
  const { id, title, route, callback, ariaLabel } = navItem;

  // Adding a '/' to gatsby urls to align all urls and solve canoncial issues
  let adjustedRoute = route as string;
  if (adjustedRoute && adjustedRoute[adjustedRoute.length - 1] !== '/') {
    const [url, query = ''] = adjustedRoute.split('?');
    adjustedRoute = url[url.length - 1] === '/' ? adjustedRoute : `${url}/${query}`;
  }

  const onClick = callback && (() => callback(navItem));
  return raw ? (
    <RouterLink id={id} to={adjustedRoute} onClick={onClick} aria-label={ariaLabel}>
      {title}
    </RouterLink>
  ) : (
    <Link id={id} to={adjustedRoute} onClick={onClick} inverse={inverse} aria-label={ariaLabel}>
      {title}
    </Link>
  );
};
