import { FundType, OfferingStatus, OfferingV5 } from '@yieldstreet/platform-kit';

export function isFortyActFund(fundType?: FundType | string | null): boolean {
  return fundType === FundType.FortyActFund;
}

export function isRegAPlus(fundType?: FundType | string | null): boolean {
  return fundType === FundType.RegAPlus;
}

export function isFortyActOrRegAPlus(fundType?: FundType | string | null): boolean {
  return [FundType.RegAPlus, FundType.FortyActFund].includes(fundType as FundType);
}

export function isInternalOrThirdPartyFund(fundType?: FundType | string | null): boolean {
  return [FundType.InternalFund, FundType.ThirdPartyFund].includes(fundType as FundType);
}

export function getOfferingTermSuffix(term?: number | null) {
  if (!term) {
    return '';
  }

  return term > 1 ? 'months' : 'month';
}

export function getOfferingTerm(offering: OfferingV5) {
  const { term, status } = offering;

  if (offering?.offeringHero?.customTerm) {
    return {
      term: offering?.offeringHero?.customTerm,
      termSuffix: offering?.offeringHero?.customTermSuffix || '',
    };
  }

  if (
    [
      OfferingStatus.open,
      OfferingStatus.pending,
      OfferingStatus.pendingFullyCommitted,
      OfferingStatus.closed,
      OfferingStatus.payout,
    ].includes(status as OfferingStatus)
  ) {
    return {
      term: offering?.computedTerm,
      termSuffix: getOfferingTermSuffix(offering?.computedTerm),
    };
  }

  if (status === OfferingStatus.repaid) {
    return {
      term: offering?.actualTerm,
      termSuffix: getOfferingTermSuffix(offering?.actualTerm),
    };
  }

  return {
    term: term,
    termSuffix: getOfferingTermSuffix(term),
  };
}
