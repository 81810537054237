import React, { FunctionComponent } from 'react';

import { Heading, Label, Paragraph } from '../../content';
import { TextBlock } from '../text-block-component';
import { MarketingContainer } from '../../layout';

import {
  Advantage,
  AdvantagesContainer,
  Container,
  Icon,
  SHeading,
  SParagraph,
  Stat,
  TextWrapper,
  SSectionContainer,
} from './AdvantagesSection.styles';

import {
  AdvantagesSectionProps,
  IconSize,
  childrenAmountSmallWidth,
} from './AdvantagesSection.model';

export const AdvantagesSection: FunctionComponent<AdvantagesSectionProps> = ({
  label,
  heading,
  content,
  items,
  iconsSize = IconSize.Small,
  align,
  mobileAlign,
  hasPadding,
}) => {
  const smallItems = items.length === childrenAmountSmallWidth;

  return (
    <MarketingContainer>
      <SSectionContainer>
        <Container hasSmallItems={smallItems} hasPadding={hasPadding}>
          <TextWrapper align={align}>
            <TextBlock
              label={label}
              heading={heading}
              headingTagType={2}
              headingType={2}
              content={<Paragraph>{content}</Paragraph>}
              align={align}
              mobileAlign={mobileAlign}
            />
          </TextWrapper>
          <AdvantagesContainer hasSmallItems={smallItems}>
            {items.map((advantage, index) => (
              <Advantage key={index}>
                <Icon src={advantage.icon} iconsSize={iconsSize} role="presentation" alt="" />
                <Heading tagType={4} type={4}>
                  {advantage.title}
                </Heading>
                <SParagraph small>{advantage.description}</SParagraph>
                {advantage.stat && (
                  <Stat>
                    <SHeading type={3} tagType={3}>
                      {advantage.stat?.value}
                    </SHeading>
                    <Label small>{advantage.stat?.label}</Label>
                  </Stat>
                )}
              </Advantage>
            ))}
          </AdvantagesContainer>
        </Container>
      </SSectionContainer>
    </MarketingContainer>
  );
};
