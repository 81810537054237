import React, { FunctionComponent, ReactNode, useMemo } from 'react';

import { Label, Paragraph } from '../../content';
import fullStar from './assets/Star.svg';
import halfStar from './assets/HalfStar.svg';

import { RateWrapper, RateAmount, Rate, AwardContainer, TextWrapper } from './Award.style';

export interface AwardsProps {
  image: ReactNode;
  dataCy?: string;
  title?: string;
  score?: number;
  additionalInfo?: string | ReactNode;
}

export const Awards: FunctionComponent<AwardsProps> = ({
  image,
  dataCy,
  title,
  additionalInfo,
  score = 0,
}) => {
  const finalStars = useMemo(() => {
    const roundedScore = Math.floor(score);

    let stars: any[] = [];
    stars = Array(roundedScore).fill(<img src={fullStar} alt="full star" />);
    score % 1 !== 0 && stars.push(<img src={halfStar} alt="half star" />);

    return stars;
  }, [score]);

  return (
    <AwardContainer data-cy={dataCy}>
      {image}
      {score ? (
        <RateWrapper>
          <Rate data-cy="award-container-score-image">{finalStars}</Rate>
          <RateAmount big>{score}</RateAmount>
        </RateWrapper>
      ) : null}
      <TextWrapper>
        {title ? (
          <Paragraph semiBold data-cy="award-container-title">
            {title}
          </Paragraph>
        ) : null}
        {additionalInfo ? (
          <Label data-cy="award-container-additional-info">{additionalInfo}</Label>
        ) : null}
      </TextWrapper>
    </AwardContainer>
  );
};
