import React, { useRef, useState } from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import { Paragraph } from '../../content';
import { MarketingContainer } from '../../layout';
import { useResponsiveFriendly } from '../../utils';

import {
  Button,
  IconWrapper,
  Image,
  ImageWrapper,
  Tab,
  TabsWrapper,
  Title,
  Wrapper,
} from './ContentTabs.style';

interface TabProps {
  icon?: React.ReactNode;
  image: React.ReactNode;
  title: string;
  content: string;
  buttonLink?: string;
  buttonText?: string;
}

interface Props {
  tabs: TabProps[];
  dataCy?: string;
}

const visibilityOptions = {
  scrollDelay: 200,
  offset: { top: 500 },
  scrollCheck: true,
};

export const ContentTabs = ({ tabs, dataCy }: Props) => {
  const viewPort = useResponsiveFriendly();
  const isMobile = viewPort === 'mobile';
  const imgRef = useRef<HTMLDivElement>(null);

  const [activeTab, setActiveTab] = useState(0);

  return (
    <MarketingContainer>
      <Wrapper data-cy={dataCy}>
        <ImageWrapper ref={imgRef}>
          {tabs.map((tab, tabIndex) => (
            <Image
              visible={tabIndex === activeTab}
              key={`tab-img-${tabIndex}`}
              data-cy={`content-img-${tabIndex}`}
            >
              {tab.image}
            </Image>
          ))}
        </ImageWrapper>
        <TabsWrapper>
          {tabs.map((tab, tabIndex) => (
            <VisibilitySensor
              {...visibilityOptions}
              active={isMobile}
              onChange={isVisible => {
                isVisible && setActiveTab(tabIndex);
              }}
              key={`content-tab-${tabIndex}`}
            >
              <Tab
                onMouseEnter={() => !isMobile && setActiveTab(tabIndex)}
                onClick={() => isMobile && setActiveTab(tabIndex)}
                active={tabIndex === activeTab}
                id={`content-tab-${tabIndex}`}
              >
                {tab.icon && <IconWrapper>{tab.icon}</IconWrapper>}
                <Title type={4}>{tab.title}</Title>
                <Paragraph secondary>{tab.content}</Paragraph>
                {tab.buttonLink && (
                  <Button href={tab.buttonLink} target="_blank">
                    {tab.buttonText}
                  </Button>
                )}
              </Tab>
            </VisibilitySensor>
          ))}
        </TabsWrapper>
      </Wrapper>
    </MarketingContainer>
  );
};
