import React, { useState, useEffect } from 'react';
import { PageRendererProps } from 'gatsby';
import { forOwn } from 'lodash';
import { WindowLocation } from '@reach/router';

import { Header, Footer, zendeskIdentify, HeaderLoggedOut } from '@yieldstreet/ui-kit';
import {
  UserState,
  AudienceResolution,
  AudienceResolutionMetaData,
  TierAudienceMetaData,
} from '@yieldstreet/platform-kit';

import axios from 'axios';
import { renderLink } from 'components/ui/Link';

interface MainLayoutProps extends PageRendererProps {
  shouldBeAuthenticated?: boolean;
}

// load user state from local storage
export const loadUserState = (): UserState | undefined => {
  try {
    const serializedUserState = localStorage.getItem('userState');
    if (serializedUserState) {
      return JSON.parse(serializedUserState);
    }
  } catch (e) {
    return;
  }
};

// save current use state to local storage
export const saveUserState = (userState: UserState) => {
  try {
    const serializedUserState = JSON.stringify(userState);
    localStorage.setItem('userState', serializedUserState);
  } catch (e) {
    return;
  }
};

export const useUserStateNavigation = (
  location?: WindowLocation,
  shouldBeAuthenticated?: boolean
) => {
  const [userState, setUserState] = useState<UserState>();

  useEffect(() => {
    const userState = loadUserState();
    setUserState(userState);
    if (userState && userState.loggedIn) {
      axios.get<UserState>('/a/api/user/state').then(res => {
        setUserState(res.data);
        if (res.data.loggedIn) {
          zendeskIdentify(res.data);
        }
      });
    } else if (shouldBeAuthenticated && location) {
      window.location.href = `/login?redirect=${encodeURIComponent(location.pathname)}`;
    }
  }, []);

  useEffect(() => {
    if (userState) {
      saveUserState(userState);
    }
  }, [userState]);

  return userState;
};

const mapTier = (
  audiences: AudienceResolutionMetaData | undefined
): TierAudienceMetaData | undefined => {
  if (audiences?.tier?.active) {
    return { ...audiences?.tier.meta };
  }
  return;
};

const mapAudiences = (
  audiences: AudienceResolutionMetaData | undefined
): AudienceResolution | {} => {
  const flatAudiences: AudienceResolution = {};
  audiences &&
    forOwn(audiences, (value, key) => {
      if (value) {
        flatAudiences[key as keyof AudienceResolution] = value.active;
      }
    });
  return flatAudiences;
};

const loadAudiences = (): AudienceResolutionMetaData | undefined => {
  try {
    const serializedAudiences = localStorage.getItem('audiences');
    if (serializedAudiences) {
      const audiences = JSON.parse(serializedAudiences);
      return audiences.data.audiences;
    }
  } catch (e) {
    return;
  }
};

export const useAudiences = (): {
  audiences: AudienceResolution | {};
  tier: TierAudienceMetaData | undefined;
} => {
  const [audiences, setAudiences] = useState({});

  useEffect(() => {
    const lsAudiences = loadAudiences();
    setAudiences(lsAudiences || {});
  }, []);

  return {
    audiences: mapAudiences(audiences),
    tier: mapTier(audiences),
  };
};

const loadUnreadMessages = () => {
  try {
    const serializedUnreadMessages = localStorage.getItem('unreadMessages');
    if (serializedUnreadMessages) {
      const unreadMessages = JSON.parse(serializedUnreadMessages);
      return unreadMessages?.length || 0;
    }
  } catch (e) {
    return;
  }
};

interface PageContextProps {
  location?: WindowLocation;
}

export const PageContext = React.createContext<PageContextProps>({});

const MainLayout: React.FunctionComponent<MainLayoutProps> = ({
  location,
  children,
  shouldBeAuthenticated,
}) => {
  const userState = useUserStateNavigation(location, shouldBeAuthenticated);
  const { audiences, tier } = useAudiences();

  const notificationGroup = [
    {
      menu: 'activity',
      subMenu: '',
      value: loadUnreadMessages(),
    },
  ];

  return (
    <PageContext.Provider value={{ location }}>
      {!userState?.loggedIn ? (
        <HeaderLoggedOut
          audiences={audiences}
          tier={tier}
          userState={userState}
          renderLink={renderLink}
          currentPath={location.pathname}
          staticPage
          notificationGroup={notificationGroup}
        />
      ) : (
        <Header
          audiences={audiences}
          tier={tier}
          userState={userState}
          renderLink={renderLink}
          currentPath={location.pathname}
          staticPage
          notificationGroup={notificationGroup}
        />
      )}
      {children}
      <Footer renderLink={renderLink} staticPage />
    </PageContext.Provider>
  );
};

export default MainLayout;
