import { useEffect, useRef, useState } from 'react';
import {
  bankAccountApi,
  GateObject,
  paymentMethodsApi,
  SocketData,
} from '@yieldstreet/platform-kit';

export const useTrackInvestmentNextStep = (data: SocketData | undefined, userId: number) => {
  const prevInvestmentGateRef = useRef<SocketData['investmentGates'] | undefined>();
  const [gates, setGates] = useState<GateObject[]>();
  const { useLazyGetBankAccountsQuery } = bankAccountApi;
  const { useLazyPaymentMethodsQuery } = paymentMethodsApi;

  const [getBankAccounts] = useLazyGetBankAccountsQuery();
  const [getPaymentMethods] = useLazyPaymentMethodsQuery();

  const verifyGate = (gate: GateObject) => {
    switch (gate.type) {
      default:
        return gate.status === 'CLEARED';
    }
  };

  useEffect(() => {
    if (prevInvestmentGateRef?.current) {
      const receivedGates = data?.investmentGates || [];
      const prevGates = prevInvestmentGateRef.current;
      const newGates = prevGates.filter(gate => {
        const newGate = receivedGates.find(g => g.type === gate.type);

        return !newGate || verifyGate(newGate);
      });
      setGates(newGates);
    }

    prevInvestmentGateRef.current = data?.investmentGates;
  }, [data?.investmentGates]);

  useEffect(() => {
    if (!gates?.length || !data) {
      return;
    }

    gates.forEach(gate => {
      switch (gate.type) {
        case 'BANK_ACCOUNT_PENDING': {
          getBankAccounts({
            userId,
          });

          getPaymentMethods({
            investmentAmount: data.investment?.amount || 0,
            investorAccountId: +(data.investment?.investorAccountId || 0),
            urlHashes: [data.investment?.noteUrlHash || ''],
          });
        }
      }
    });
  }, [data, gates, getBankAccounts, getPaymentMethods, userId]);

  return gates;
};
